import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
//Images
import KramedaLogo from '../../SVG/Logo/Krameda_WithoutBackground.png'

import { IonGrid, IonCol } from '@ionic/react';

import "./HeaderBar.css";
import "../../CSS/globalStyles.css";


import BurgerMenue from './components/BurgerMenue';
import HeaderContent from './components/HeaderContent';


import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLogo } from '../../slices/logoSlice';
import { createAnimation } from '@ionic/core';


function HeaderBar() {

  const logo = useSelector(state => state.logo.logoState);
  const [smallDevice, setSmallDevice] = useState(window.innerWidth < 768);

  
  const imgRef = useRef(null);
  const dispatch = useDispatch();

  function animateImageIn() {
    const animation = createAnimation()
      .addElement(imgRef.current)
      .duration(1250)
      .iterations(1)
      .fromTo('filter', 'blur(10px)', 'blur(0px)')
      .fromTo('transform', 'scale(0.5)', 'scale(1)')
    animation.play();
  }

  function animateImageOut() {
    const animation = createAnimation()
      .addElement(imgRef.current)
      .duration(1250)
      .iterations(1)
      .fromTo('filter', 'blur(10px)', 'blur(0px)')
      .fromTo('transform', 'scale(1)', 'scale(0)')
    animation.play();
  }

  function handleResize() {
    if (window.innerWidth < 768) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  }

  useEffect(() => {
    dispatch(setLogo({ logoState: true }));

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);
  
  useEffect(() => {
    if(logo.logoState === true){
      animateImageIn();
    }else{
      animateImageOut();
    }

  }, [logo.logoState])


  

  return (
    <>
      <IonGrid container className='navi-bar' style={{ width: "100%", height: "100%", alignItems: "center" }} >
        <IonCol style={{ height: "100%", width: "100%" }}>
          <Link to="/">
              <img ref= {imgRef} src={KramedaLogo} alt="Logo Krameda" style={{ height: '100%', cursor: "pointer", }} />
          </Link>
        </IonCol >
        <IonCol size="9" style = {{display: "flex", justifyContent: "flex-end", alignItems:"center"}}>
          {smallDevice ?
            (<BurgerMenue />) :
            (<HeaderContent />)
          }
        </IonCol>
      </IonGrid>
    </>
  );
}

export default HeaderBar;
