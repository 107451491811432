import { IonCol, IonGrid, IonRow } from '@ionic/react';

import "./timelineAndVIsion.css"

//Assets
import firstPoint from "../assets/LinePoints/1Point.png"
import secondPoint from "../assets/LinePoints/2Point.png"
import thirdPoint from "../assets/LinePoints/3Point.png"
import fourthPoint from "../assets/LinePoints/4Point.png"

import SCEBeginn from "../assets/TimelineAndVision/SCE_Beginn.png";
import startIdea from "../assets/TimelineAndVision/01_OurIdea.png";
// import startIdea from "../assets/TimelineAndVision/startIdea.png"; //TODO: Helply logo entfernen
import ExistWomen from "../assets/TimelineAndVision/ExistWomen.png";
import GewerbeEintragung from "../assets/TimelineAndVision/GewerbeEintragung.png";

import { createAnimation } from '@ionic/core';
import { useEffect, useRef } from 'react';

import ThemedText from '../../../components/ThemedText';

import { useState } from 'react';

export default function TimelineAndVision() {
    const historyCard = useRef(null);


    const historyPoint=[
        {
            date: "07/2023",
            title:"Unsere Idee entsteht",
            description: "Dank unserer langjährigen Erfahrung in der ehrenamtlichen Unterstützung und Alltagshilfe sind wir uns der Herausforderungen bewusst, die mit dem Wunsch zu helfen einhergehen: Die Suche nach Alltagshelfern ist sowohl für die Helfenden als auch für die Hilfesuchenden oft kompliziert und zeitaufwendig. Im Sommer 2023 reifte daher unsere Idee, diese Schwierigkeiten anzugehen und eine Lösung zu schaffen: Wir wollen eine Plattform gründen, auf der lokale Alltagshelfer einfach, flexibel und schnell gefunden werden können.",
            image: firstPoint,
            promoImg: startIdea,

        },
        {
            date: "10/2023",
            title:"Spotlight im SCE",
            description: "Einen bedeutenden Meilenstein erreichten wir im September 2023, als wir in das Batch #10 des Accelerator-Programms des Strascheg Center for Entrepreneurship (SCE) aufgenommen wurden. Diese Aufnahme war nicht nur eine Bestätigung unserer Idee, sondern bot uns vor allem ein inspirierendes Umfeld, in dem wir unser Konzept und unser Team weiterentwickeln konnten. Dank regelmäßiger Experten-Coachings, Trainings und Workshops hatten wir die Gelegenheit, sowohl unser Produkt als auch uns selbst kontinuierlich zu verbessern.",
            image: secondPoint,
            promoImg: SCEBeginn,
        },
        {
            date: "11/2023",
            title:"Förderung EXIST Women",
            description: "Ein weiterer bedeutender Meilenstein war die Aufnahme unseres Teammitglieds Kristin in das renommierte EXIST Woman Programm. Dieses exklusive Netzwerk für gründungsinteressierte Frauen bietet nicht nur spezielle Workshops und Events, sondern legt besonderen Wert darauf, Frauen als Gründerinnen zu stärken und zu fördern. Da Frauen in der Gründerszene nach wie vor unterrepräsentiert sind, schafft EXIST Woman eine inspirierende Plattform, die Frauen ermutigt, ihre innovativen Ideen zu verwirklichen und sich in der heutigen Gesellschaft erfolgreich zu positionieren.",
            image: thirdPoint,
            promoImg: ExistWomen,
        },
        {
            date: "08/2024",
            title:"Wir werden aktiv!",
            description: 'Über den Sommer hinweg haben wir alle notwendigen juristischen Schritte unternommen, um uns als OHG zu etablieren. Die Website haben wir inklusive Datenschutz und Impressum fertiggestellt. Nun können wir endlich das tun, was von Anfang an unser Ziel war: helfen! Direkt zum Start haben wir auch mit Marketingmaßnahmen begonnen und freuen uns darauf, Menschen zu unterstützen. So rückt auch die Realisierung unseres Projekts "Helfen für alle - die Online-App" ein großes Stück näher!',
            image: fourthPoint,
            promoImg: GewerbeEintragung,
        }
    ];

    const [selectedPoint, setSelectedPoint] = useState(historyPoint[0]);


    function handlePointClick(index) {
        const historyCardElement = historyCard.current;
    
        // Animation für das aktuelle Element (wegverschieben nach links)
        const exitAnimation = createAnimation()
            .addElement(historyCardElement)
            .duration(500)
            .iterations(1)
            // .fromTo('transform', 'translateX(0)', 'translateX(-100%)')
            .fromTo('opacity', '1', '0');
            exitAnimation.play();

            setTimeout(()=>{
                setSelectedPoint(historyPoint[index]);
            }, 500)

    }
    //Animations
    useEffect(() => {
        const historyCardElement = historyCard.current;

            // Animation für das neue Element (eintreten von rechts)
            const enterAnimation = createAnimation()
                .addElement(historyCardElement)
                .duration(1000)
                .iterations(1)
                .fromTo('transform', 'translateX(100%)', 'translateX(0)')
                .fromTo('opacity', '0', '1');
    
            // Starten der Enter-Animation
            enterAnimation.play();

    }, [selectedPoint]);
    
    

    return (
        <IonGrid className="grid-timelineAndVision">
            <IonRow>
                <IonGrid style = {{display:"flex", justifyContent: "center", margin: 0, padding: 0}}>
                <p className = "section-title">WIE ALLES BEGANN...</p>

                </IonGrid>
                    <IonRow>
                        {historyPoint.map((point, index) => (
                            <IonCol sizeLg={3} className="col-textAndPoint" key={index}>
                                <IonGrid>
                                    <p className="point-date">{point.date}</p>
                                    <p className="point-text">{point.title}</p>
                                </IonGrid>
                                <img
                                    className="point"
                                    onClick={() => handlePointClick(index)}
                                    src={point.image}
                                    alt={`Point ${index}`}
                                />
                            </IonCol>
                        ))}
                    </IonRow>

                    <IonRow>
                        <IonGrid style={{ padding: "5px", paddingTop: "0" , textAlign: "justify" }}>
                            {
                                selectedPoint &&
                                (
                                    <>
                                        <IonGrid ref = {historyCard} className="grid-time-img-selectedPoint">
                                            <IonRow  style = {{display:"flex", justifyContent: "flex-start", height: "60%", padding: "10px" }}>
                                                <IonCol sizeLg='4' sizeXs='12' className="grid-img-selectedPoint">
                                                <img className="promoImg" src={selectedPoint.promoImg} alt="Promo" />
                                            </IonCol>
                                            <IonCol sizeLg='8' sizeXs='12' className="grid-description" >
                                                <IonGrid style = {{dislay:"flex", justifyContent:"center", textAlign: "center"}}>
                                                    <ThemedText variant="p-bright-mini">{selectedPoint.date}</ThemedText>
                                                    <ThemedText variant="h3-bright">{selectedPoint.title}</ThemedText>
                                                </IonGrid>
                                                <IonRow style={{ padding: "10", paddingTop: "10px", }}>
                                                    <ThemedText variant="p-bright">{selectedPoint.description}</ThemedText>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                    </>
                                )
                            }
                        </IonGrid>
                    </IonRow>
            </IonRow>
        </IonGrid>

    )
}