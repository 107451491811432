
//Eigene Komponenten
import Banner from './components/banner';
import Footer from '../../components/footer/Footer.jsx';
//REDUX
import React, { useEffect, useState } from 'react';


//IONIC
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';


import { IonPage, IonContent, IonGrid } from '@ionic/react'; // Importieren Sie IonPage aus @ionic/react
// import { CreateAnimation, Animation } from '@ionic/react';
import PromotionKrameda from './components/PromotionKrameda';
import DeepInfosKrameda from './components/DeepInfosKrameda';
import TimelineAndVision from './components/TimelineAndVision';
import OurVision from './components/OurVision.jsx';
import ImagesContainer from './components/ImagesContainer';

import { useRef } from 'react';
import {  useDispatch } from 'react-redux';

import { setLogo } from '../../slices/logoSlice';


//Animation
import { createAnimation } from '@ionic/core';

import { IonCol, IonRow } from '@ionic/react';

import { Grid} from "@mui/material";
import './components/teamSection.css';
import '../../CSS/bubble.css';
import '../../CSS/fonts.css';
import ThemedText from "../../components/ThemedText.jsx";
import { FounderCard, BioFounder } from './components/CardsAndBios_TeamSection.jsx';

setupIonicReact();

function HomePage() {
  const [isPromotionKramedaVisible] = useState(false);

  const dispatch = useDispatch();
  const promotionKramedaRef = useRef(null);
  const deepInfoRef = useRef(null);
  const OurVisionRef = useRef(null);  

  // const logo = useSelector(state => state.logo.logoState);


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5 // Element muss zu 50% sichtbar sein, damit die Funktion ausgeführt wird
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if(entry){
          if (entry.isIntersecting) {
            dispatch(setLogo({ logoState: false }));
          }
          else {
            dispatch(setLogo({ logoState: true }));
          }
        }

      });
    }, options);
    let pKRef = promotionKramedaRef.current;
    if (pKRef) {
      observer.observe(pKRef);
    }
    return () => {
      if (pKRef) {
        observer.unobserve(pKRef);
      }
    };

  }, [dispatch]);

  useEffect(() => {
    if (!isPromotionKramedaVisible) {
    }
  }, [isPromotionKramedaVisible]);

  
  //NEWS------------------------
  // const [news, setNews] = useState([]);

  // useEffect(() => {
  //   getLatestNews(setNews);
  // }, []);

  useEffect(() => {
    const animation = createAnimation()
        .addElement(OurVisionRef.current)
        .duration(1500)
        .iterations(1)
        .fromTo('transform', 'translateX(50px)', 'translateX(-50px)')
        .fromTo('opacity', '0', '1');

    animation.play();
}, []);



  const executeScroll = () => {
    deepInfoRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  

  return (
    <IonPage className="page-design">
      <IonContent>
        <IonGrid ref={promotionKramedaRef} style={{ padding: 0, margin: 0, height: "100%" }}>
          <PromotionKrameda scrollToSection={executeScroll} />
        </IonGrid>

        <IonGrid style={{ margin: 0, padding: 0, borderBottom: "#002417 2px solid" }} ref={deepInfoRef} >
          <DeepInfosKrameda className="deepInfosKrameda" />
        </IonGrid>
        <TimelineAndVision />
        <OurVision />
        <Banner />
        <TeamSection />
        <ImagesContainer />
        <Footer />
      </IonContent>
    </IonPage>

  );
}

export default HomePage;


const founders = [
    { id: "dennis"}, { id: "kristin"}, { id: "mario"}
];



// CLOUDFLARE: DIDN'T FIND TEAMSECTION UNDER /COMPONENTS - ADDED IT HERE
function TeamSection() {
    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const cardRef = useRef(null);

    function handleCardClick(person) {
    if (cardRef.current) {
        cardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
        setSelectedPerson(person);
    }
    
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if(entry){
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
              
              }

            });
        }, { threshold: 0.2 }); // Threshold-Wert hier ändern, um Ihre Anforderungen anzupassen
        const cRef = containerRef.current;
        observer.observe(cRef);
    
        // Aufräumen: Observer trennen, wenn die Komponente demontiert wird
        return () => {
            observer.unobserve(cRef);
        };
    }, [isVisible]); // Abhängigkeit von isVisible entfernt, da sie nicht erforderlich ist
    


    return (
        <Grid className="doubleblueline" style={{ position: "relative"}}>
            <div className="bubbles">
              {[...new Array(10)].map((_, index) => (
                    <div key={index} className="bubble"></div>
              ))}
                <Grid
                    ref={containerRef} // Ref für das Grid-Element
                    style={{
                        opacity: isVisible ? 1 : 0, // Opazität basierend auf Sichtbarkeitsstatus setzen
                        transition: 'opacity 2s ease-in-out', // Übergangseffekt für Opazität
                    }}
                >
            <IonGrid ref={cardRef} style = {{padding: "0px", margin: "0px"}}>
              <IonGrid  style = {{padding: "0px", margin: "0px"}}>
                <ThemedText variant="h1-bright">Die Gesichter hinter Krameda</ThemedText>
              </IonGrid>
              <IonRow style={{ justifyContent: "center"}}>
                {selectedPerson === null ? (
                  founders.map((founder, index) => (
                    <IonCol key = {index} sizeLg="2.5" sizeMd="4" sizeSm="6" sizeXs="11">
                      <FounderCard key={founder.id} founder={founder.id} onClick={() => handleCardClick(founder.id)} />
                    </IonCol>
                  ))
                ) : (
                  <Grid container className='greybackground' >
                    <Grid container spacing={2} style={{ justifyContent: "center", alignItems: "center" }}>
                      <Grid justifyContent="flex-start">
                        <FounderCard founder={selectedPerson} onClick={() => handleCardClick(null)} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <BioFounder founder={selectedPerson} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </IonRow>
            </IonGrid>
          </Grid>
        </div>
        </Grid>
    )
}